<script>
/* eslint-disable */
import DefaultMixin from '../mixin'

export default {
  name: 'LeilaoAutomacao',
  inject: ['page'],
  mixins: [DefaultMixin],
  components: {
  },
  data() {
    return {
      leilao: this.page.leilao
    }
  },
  computed: {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
  },
  meta: {
    title: 'Automação',
    name: 'Automacao'
  }
}
</script>

<template>
  <div class="page-leilao-container">
    Módulo em processo de atualização, consulte seu gerente de contas para verificar o andamento da ativação.
  </div>
</template>
